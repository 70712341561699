<template>
  <div class="pages">
    <!--顶部头像-->
    <div class="headerInfo">
      <img class="headBack" src="../static/images/main/banner_null.png" width="100%"/>
      <div class="backView">
        <span class="Back" @click="back()">返回</span>
      </div>
      <div class="userInfo">
        <img v-if="user.Sex == '男'" :src="user.Head != ''?user.Head:headImgUrl" class="round_icon" :style="{border:'solid 2px #AACC03'}">
        <img v-if="user.Sex == '女'" :src="user.Head != ''?user.Head:headImgUrl" class="round_icon" :style="{border:'solid 2px #E75296'}">
        <span>{{ user.Name }}</span>
      </div>
    </div>

    <div class="fitnessInfo">
      <span class="line1">{{ fitName }}</span>
      <span class="line2">{{ shopName.slice(5) }}</span>
      <span class="line3">{{ fitTime |filterTime }}</span>
    </div>
    <div class="info">
      <ul>
        <li>
          <em>{{ height }}</em>
          <span>身高cm</span>
        </li>
        <li>
          <em>{{ age }}</em>
          <span>年龄</span>
        </li>
        <li>
          <em>{{ weight }}</em>
          <span>体重kg</span>
        </li>

      </ul>
    </div>

    <!--课程详情-->
    <div class="pageDetail">

      <!--动作评分-->
      <div class="pageScore" v-show="true">
        <ul class="scoreContent">
          <li v-for="scoreItem in fitnessDetailRs">
            <span class="left">{{ scoreItem.FtiName }}</span>
            <span class="right">
              {{ scoreItem.FtValue }}
              <em>{{ scoreItem.Mu }}</em>
            </span>
          </li>
        </ul>
      </div>
    </div>

    <!--底部课程统计-->
    <bottomTab :curTab="thisTab"/>
  </div>
</template>

<script>
import axios from 'axios';
import bottomTab from '../components/bottomTab'
import {
  GetSportPercentByDuId,
  GetUserHistoryInfoByDuId,
  VipUserSelfQuery,
  PersonClassDetail,
  FtoDetailQuery,
  GetHrValueInfoByDuId,
} from '../api/getApiRes.js'

let qs = require('qs');
import Global from '../Global.js'
// 引入基本模板
let echarts = require('echarts/lib/echarts')
// 引入柱状图组件
require('echarts/lib/chart/bar')
require('echarts/lib/chart/line')
// 引入提示框和title组件
require('echarts/lib/component/title')
require('echarts/lib/component/tooltip')
require('echarts/lib/component/dataZoom')
require('echarts/lib/component/legend')
require('echarts/lib/component/grid')

export default {
  data() {
    return {
      shopName: '',
      age:'',
      height:'',
      weight:'',
      ftoId: '',
      fitName: '',
      fitTime: '',
      thisTab: '我的运动',
      screenWidth: document.body.clientWidth + 'px',
      headImgUrl:require('../static/images/mine/header_default.png'),
      classDetail: {},//课程基础信息
      userClassInfo: {},//用户上课时的体态信息
      fitnessDetailRs: [],//用户评分课结果
      visibleChartAcPercent: false,//课堂活动百分比柱状图显示控制
      visibleChartHeartRate: false,//心率折线图显示控制
      visiblePageScore: false,//评分结果内容显示控制
      active: 0,
      user: {
        Name: '',
        Age: '',
        Height: '',
        Weight: '',
        StaticHr: '',
        Phone: '',
        Sex: '',
        Head: '',
        CKToTal: 0,
        CKMonth: 0,
        CalorieMonth: 0,
        CalorieTotal: 0,
      }
    }
  },
  watch: {
    '$route'(to) {
      if (to.name == 'fitnessDetail') {
        this.shopName = this.$route.query.shopName;
        this.ftoId = this.$route.query.ftoId;
        this.fitName = this.$route.query.fitName;
        this.fitTime = this.$route.query.fitTime;
        this.age = this.$route.query.age;
        this.height = this.$route.query.height;
        this.weight = this.$route.query.weight;
        this.getUserInfo();//获取用户基本信息
        this.getFitnessDetail();//获取测评详情
      }
    },
  },
  mounted() {
    let that = this;
    this.shopName = this.$route.query.shopName;
    this.ftoId = this.$route.query.ftoId;
    this.fitName = this.$route.query.fitName;
    this.fitTime = this.$route.query.fitTime;
    this.age = this.$route.query.age;
    this.height = this.$route.query.height;
    this.weight = this.$route.query.weight;
    this.getUserInfo();//获取用户基本信息
    this.getFitnessDetail();//获取测评详情
  },
  methods: {
    //返回上一页
    back() {
      this.$router.push({path: '/fitnessRecord'});
    },
    //获取用户测评时的基本信息
    getUserInfo() {
      let that = this;
      let param = {
        token: localStorage.token,
        ftoId: that.ftoId
      };
      let postData = qs.stringify(param);
      VipUserSelfQuery(postData).then(res => {
        let json = res;
        if (json.Code == 0) {
          that.user = json.Rs;
        } else {
          that.$toast.message(json.Memo);
        }
      })
    },
    //获取测评详情
    getFitnessDetail() {
      let that = this;
      let param = {
        token: localStorage.token,
        ftoId: that.ftoId
      };
      let postData = qs.stringify(param);
      FtoDetailQuery(postData).then(res => {
        let json = res;
        if (json.Code == 0) {
          that.fitnessDetailRs = json.Rs;
        } else {
          that.fitnessDetailRs = [];
          // that.$toast.message(json.Memo);
        }
      })
    },
  },
  filters: {
    hidePhone: function (val) {
      let str = String(val)
      let len = str.length;
      if (len >= 7) {
        let reg = str.slice(-8, -4)
        return str.replace(reg, "****")
      } else if (len < 7 && len >= 6) {
        let reg = str.slice(-4, -2)
        return str.replace(reg, "**")
      }
    },
    filterTime: function (value) {
      if (!value) {
        return 0;
      } else {
        let resStr = dateFormatWithoutZone(value, 16);
        return resStr;
      }
    },
    fmtTime: function (value) {
      if (!value) {
        return 0
      } else {
        // js默认使用毫秒
        value = value * 1000
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ('0' + MM) : MM;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        let m = date.getMinutes();
        m = m < 10 ? ('0' + m) : m;
        let s = date.getSeconds();
        s = s < 10 ? ('0' + s) : s;
        return y + '-' + MM + '-' + d;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    // A跳转到B，B页面停留在A页面的滚动位置；解决方法：将scrollTop设置为0
    next(vm => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      if (to.name == 'reportLessonDetail') {
        vm.getUserInfo();
      }
    });
  },
  components: {
    bottomTab
  }
}
</script>

<style scoped>
.pages {
  overflow: hidden;
  display: block;
  margin: 0 auto;
  background: #F2F2F2;
}

.headerInfo {
  position: relative;
}

.headerInfo .headBack {
  height: 120px;
  position: absolute;
  z-index: 1;
}

.headerInfo .backView {
  left: 5%;
  top: 1.5rem;
  position: absolute;
  z-index: 2;
  text-align: center;
}

.headerInfo .Back {
  width: 50px;
  height: 25px;
  font-size: 14px;
  border-radius: 2px;
  line-height: 26px;
  color: #F4F4F4;
  display: block;
  margin-top: 60px;
  margin-left: -10px;
  background: rgba(255, 255, 255, 0.24);
  text-align: center;
}


.headerInfo .userInfo {
  left: 42%;
  top: 1.25rem;
  position: absolute;
  z-index: 2;
  text-align: center;
}

.headerInfo .userInfo span {
  font-size: 16px;
  color: white;
  display: block;
  margin-top: 5px;
}

.round_icon {
  width: 64px;
  height: 64px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 3px;
  border: solid 2px white;
}

.fitnessInfo {
  height: 80px;
  color: black;
  margin-top: 120px;
  text-align: center;
  padding-top: 10px;
}

.fitnessInfo .line1 {
  display: block;
  color: #E75296;
  font-size: 16px;
}

.fitnessInfo .line2 {
  display: block;
  font-weight: bold;
  font-weight: normal;
  font-size: 14px;
}

.fitnessInfo .line3 {
  display: block;
  font-size: 12px;
}

.info {
  background: #fff;
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 5px auto 0;
}

.info ul {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 5px auto;
}

.info li {
  width: 33%;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  float: left;
  text-align: center;
}

.info li:nth-child(2) {
  border-color: #e2e2e2;
}

.info li:nth-child(3) {
  border-color: #e2e2e2;
  border-left: 0;
  border-right: 0;
}

.info li em {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  color: #363636;
  font-size: 20px;
  text-align: center;
}

.info li span {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  color: #c4c4c4;
  font-size: 12px;
  text-align: center;
}

.pageDetail {
  text-align: center;
  background: #FFF;
  padding-bottom: 20px;
}

.detailTitle span {
  display: block;
}

.memberInfo em {
  color: #FFA310;
  font-size: 12px;
}

.memberInfo span {
  font-size: 12px;
  color: #909090;
}

/**
动作评分结果选项
 */

.pageScore {
  background: #F2F2F2;
}

.pageScore span {
  height: 50px;
  font-size: 17px;
  font-weight: bold;
  padding-top: 10px;
  text-align: center;
  line-height: 50px;
  font-family: "PingFang SC";
}

.pageScore .scoreContent {
  margin-top: 5px;
  font-weight: normal;
}

.pageScore .scoreContent li {
  width: 100%;
  background: #FFF;
  height: 50px;
  border-bottom: solid 1px #F2F2F2;
}

.pageScore .scoreContent li span {
  font-weight: normal;
}

.pageScore .scoreContent li:first-child .right em {
}

.pageScore .scoreContent li .left {
  width: 45%;
  float: left;
  font-size: 14px;
  line-height: 30px;
  text-align: right;
  padding-right: 10%;
  display: block;
  border-right: 1px solid #F2F2F2;
}

.pageScore .scoreContent li .right {
  /*background: #EBFFE3;*/
  width: 50%;
  float: left;
  text-align: left;
  font-size: 16px;
  padding-left: 10%;
  line-height: 30px;
  display: block;
}

.pageScore .scoreContent li .right em {
  font-weight: normal;
  margin-right: 10%;
  border-radius: 15px;
  height: 25px;
  line-height: 25px;
  width: 70px;
  text-align: center;
  font-size: 12px;
  float: right;
  color: #C9C9C9;
}

@media only screen and (max-width: 640px) {

}

@media only screen and (max-width: 480px) {

}

@media only screen and (max-width: 375px) {
}

@media only screen and (max-width: 360px) {

}

@media only screen and (max-width: 320px) {

}

@media only screen and (min-width: 641px) {

}
</style>
